@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* Font Family for subheading in Blogs */
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Gochi+Hand&family=Sriracha&display=swap');

*{
    font-family: 'Inter', sans-serif;
    margin: 0;
}

/* -------------------------Scroll bar---------------------------- */

/* global.css or custom CSS file */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Lighter background for scrollbar track */
}

::-webkit-scrollbar-thumb {
  background-color: #888; /* Darker background for scrollbar thumb */
  border-radius: 10px;
  border: 2px solid transparent; /* Padding around scrollbar */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Darker color on hover */
}

/* Custom scrollbar styles */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
  border: 2px solid transparent;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}


/* ----------x--------------Scroll bar--------------x------------- */



/* ---------------- Custom cursor css ------------------------ */


@media (min-width: 768px){
.cursor {
    position: fixed;
    left: 0;
    top: 0;
    width: 32px;
    height: 32px;
    border-radius: 16px;
    background-color: rgb(255, 255, 255);
    mix-blend-mode: difference;
    z-index: 999;
    pointer-events: none;
  }
}
/* -------x-------- Custom cursor css ----------x------------- */


/* ------------------------ CSS For Blogs ------------------- */

.code-block {
  @apply bg-gray-600 text-white  rounded-xl overflow-x-auto;
}


img.blog_image {
  width: full;
}

@media (min-width: 768px) {
  /* Main Image */

  .block_content p {
    font-size: 20px;
  }
}

.block_content p {
  line-height: 1.6;
}


.block_content h1 {
  font-weight: 600;
  font-size: 40px;
  line-height: 1.2;
  margin: 10px 0 20px;
  font-family: 'Gochi Hand', cursive;
  font-family: 'Sriracha', cursive;
}
.block_content h2 {
  font-weight: 400; /* Default */
  line-height: 1.2;
  font-size: 36px;
  margin: 100px 0 20px;
  font-family: 'Gochi Hand', cursive;
  font-family: 'Sriracha', cursive;
}

.block_content h3 {
  font-weight: 300;
  font-size: 30px;
  line-height: 1.2;
  margin: 10px 0 20px;
  font-family: 'Gochi Hand', cursive;
  font-family: 'Sriracha', cursive;
}
.block_content h4 {
  font-weight: 300; /* Default */
  font-size: 26px;
  line-height: 1.2;
  margin: 100px 0 20px;
  font-family: 'Gochi Hand', cursive;
  font-family: 'Sriracha', cursive;
}


.block_content a{
  color: #731FFC;
  font-weight: 500;
}
.block_content a:hover{
  color: #b688ff;
}

.block_content img {
  margin: 2rem auto;
  border-radius: 0.8rem;
  width: 90%;
}

.block_content blockquote {
  padding: 10px 20px;
  margin: 20px;
  border-left: 4px solid #171717;
  color: #3a3a3a;
}

.block_content ul {
  list-style: circle;
  padding-left: 3rem;
  margin: 2rem auto;
}

.block_content ol {
  list-style: decimal;
  padding-left: 3rem;
  margin: 2rem auto;
}

.block_content li {
  margin: 8px auto;
}

/* Making Responsive */
@media (max-width: 700px){
  .block_content img {
    width: 95%;
    border-radius: 10px;
  }

  /* Quote in sanity */
  .block_content blockquote{
    margin: 0;
    padding: 10px;
  }
}


/* -----------x------------ CSS For Blogs ----------x-------- */